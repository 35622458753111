
<template>
    <div class="Points">
        <div class="banner">
            <div class="bannerImg">
                <img class="bg1" src="@/assets/Points/banner.png" alt="">
                <!-- <img class="bg2" src="@/assets/Home/Purpleblock.png" alt=""> -->
            </div>
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>Points</h1>
                    <p>Record you contribution in liquidity and trading on Blast. Share the potential incentive by Blaspace and Blast according to your points.</p>
                    <p>The points can also be exchanged to rewards such as Planet NFT, xSPACE, WL, etc.</p>
                </div>
            </div>
        </div>
        <div class="data">
            <div class="center connectBoxW">
                <a class="notice" href="https://twitter.com/blaspace_io/status/1704453278954914246?s=20" target="_block">Reward Redemption Phase2 (09.20-10.05): 5000points/Planet NFT, 300points/50 xSPACE! More info <img src="@/assets/Points/noticeright.png" alt=""></a>
                <div class="datalist">
                    <ul>
                        <li>
                            <p>Total Points</p>
                            <p><v-NumberGrow :valnumber="Number(TotalPoints)"></v-NumberGrow></p>
                        </li>
                        <li>
                            <p>Last Week's Points</p>
                            <p><v-NumberGrow :valnumber="Number(LastWeeksPoints)"></v-NumberGrow></p>
                        </li>
                        <li>
                            <p>Total Users</p>
                            <p><v-NumberGrow :valnumber="Number(TotalUsers)"></v-NumberGrow></p>
                        </li>
                        <li>
                            <p>Points Store</p>
                            <p class="redeem">Redeem</p>
                        </li>
                    </ul>
                    <p class="tip">The points will be Updated Weekly,through random snapshots and real-time data.</p>
                </div>
            </div>
        </div>
        <div class="nftdata">
            <div class="center connectBoxW">
                <h1>
                    <span>Hold Planet NFT to boost the Points</span>
                    <span></span>
                </h1>
                <div class="infobox">
                    <div class="infoleft">
                        <div class="infodata">
                            <div>
                                <p>
                                    <span>Total NFT Assets</span>
                                    <span>0 SPACE</span>
                                </p>
                                <p>
                                    <span>Current Amplifier</span>
                                    <span>0 x</span>
                                </p>
                            </div>
                            <div>
                                <p @click="goMint">
                                    <span>MINT</span>
                                </p>
                            </div>
                        </div>
                        <p class="inforule">Based on the amount of your Total NFT Assets, there'll be different Amplifiers:</p>
                        <ul class="inforules">
                            <li>
                                <p>Under 5,000, Amplifier <span>1.1x</span></p>
                                <p>5,000-10,000, Amplifier <span>1.15x</span></p>
                            </li>
                            <li>
                                <p>10,000-20,000, Amplifier <span>1.2x</span></p>
                                <p>20,000-50,000, Amplifier <span>1.25x</span></p>
                            </li>
                            <li>
                                <p>50,000-100,000, Amplifier <span>1.3x</span></p>
                                <p>100,000-150,000, Amplifier <span>1.35x</span></p>
                            </li>
                            <li>
                                <p>150,000-200,000, Amplifier <span>1.4x</span></p>
                                <p>Above 200,000, Amplifier <span>1.55x</span></p>
                            </li>
                        </ul>

                        <!-- <div class="Mintbtn">
                            <p @click="goMint">
                                <span>MINT</span>
                            </p>
                        </div> -->
                    </div>
                    <div class="inforight">
                        <img src="../assets/Points/planetnft.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="pointsmission">
            <div class="center connectBoxW">
                <div>
                    <h1>Points Mission</h1>
                    <p class="titletip">— — The points rules may be adjusted as appropriate — —</p>
                    <p class="tip"><span>Real-time data on Liquidity, Volume, and Holders will be Updated Weekly.</span></p>
                    <ul class="pointsmissionlist">
                        <li>
                            <div>
                                <div class="titlebox">
                                    <p>
                                        <span>Add and Hold Liquidity</span>
                                        <span>On SpaceFi</span>
                                    </p>
                                    <div>Add</div>
                                </div>
                                <div class="rule">
                                    <p>Eligible LP: $0.000000</p>
                                    <p>$ 100 liquidity, 1 point / day</p>
                                </div>
                                <div class="border"></div>
                                <div class="textinfo">The points will be accumulated based on your holding liquidity and time: $100 liquidity, 1 point/day. (Formula: points=liquidity/100*days).</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div class="titlebox">
                                    <p>
                                        <span>Add and Hold $SPACE Liquidity</span>
                                        <span>On SpaceFi</span>
                                    </p>
                                    <div>Add</div>
                                </div>
                                <div class="rule">
                                    <p>Eligible LP: $0.000000</p>
                                    <p>$ 40 liquidity, 1 point / day</p>
                                </div>
                                <div class="border"></div>
                                <div class="textinfo">The points will be accumulated based on your holding $SPACE liquidity and time: $40 liquidity, 1 point/day. (Formula: points=$SPACE liquidity/40*days).</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div class="titlebox">
                                    <p>
                                        <span>Trading Volume</span>
                                        <span>Of the pairs in Space Farm</span>
                                    </p>
                                    <div>Trade</div>
                                </div>
                                <div class="rule">
                                    <p>Eligible Volume: $0.000000</p>
                                    <p>$ 50 volume, 1 point</p>
                                </div>
                                <div class="border"></div>
                                <div class="textinfo">The points will be accumulated based on your trading volume: $50 volume, 1 point. (Formula: points=total volume/50).</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div class="titlebox">
                                    <p>
                                        <span>Trade $SPACE</span>
                                        <span>On SpaceFi</span>
                                    </p>
                                    <div>Trade</div>
                                </div>
                                <div class="rule">
                                    <p>Eligible Volume: $0.000000</p>
                                    <p>$ 20 volume, 1 point</p>
                                </div>
                                <div class="border"></div>
                                <div class="textinfo">The points will be accumulated based on your $SPACE trading volume: $20 volume, 1 point. (Formula: points=total volume/20).</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div class="titlebox">
                                    <p>
                                        <span>xSPACE Holder</span>
                                        <span>except unbonding</span>
                                    </p>
                                    <router-link tag="div" to="/xSPACE">Convert</router-link>
                                </div>
                                <div class="rule">
                                    <p>Holding Amount: 0.000000 xSPACE</p>
                                    <p>100 xSPACE, 1 point / day</p>
                                </div>
                                <div class="border"></div>
                                <div class="textinfo">The points will be accumulated based on your holding xSPACE and time: 100 xSPACE, 1 point/day. (Formula: points=xSPACE/100*days).<br />It will include the xSPACE in single pool and your wallet, except the xSPACE in unbonding.</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <v-Footer></v-Footer>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Points",
    data() {
        return {
            TotalPoints:0,
            LastWeeksPoints:0,
            TotalUsers:0,
        }
    },
    created() { },
    mounted() { 
        this.TotalPoints = 0
        this.LastWeeksPoints = 0
        this.TotalUsers = 0
    },
    methods: {
        goMint(){
            this.$router.push({path:'/Mint'})
        },
    }
}
</script>