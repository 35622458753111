var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Points"},[_vm._m(0),_c('div',{staticClass:"data"},[_c('div',{staticClass:"center connectBoxW"},[_vm._m(1),_c('div',{staticClass:"datalist"},[_c('ul',[_c('li',[_c('p',[_vm._v("Total Points")]),_c('p',[_c('v-NumberGrow',{attrs:{"valnumber":Number(_vm.TotalPoints)}})],1)]),_c('li',[_c('p',[_vm._v("Last Week's Points")]),_c('p',[_c('v-NumberGrow',{attrs:{"valnumber":Number(_vm.LastWeeksPoints)}})],1)]),_c('li',[_c('p',[_vm._v("Total Users")]),_c('p',[_c('v-NumberGrow',{attrs:{"valnumber":Number(_vm.TotalUsers)}})],1)]),_vm._m(2)]),_c('p',{staticClass:"tip"},[_vm._v("The points will be Updated Weekly,through random snapshots and real-time data.")])])])]),_c('div',{staticClass:"nftdata"},[_c('div',{staticClass:"center connectBoxW"},[_vm._m(3),_c('div',{staticClass:"infobox"},[_c('div',{staticClass:"infoleft"},[_c('div',{staticClass:"infodata"},[_vm._m(4),_c('div',[_c('p',{on:{"click":_vm.goMint}},[_c('span',[_vm._v("MINT")])])])]),_c('p',{staticClass:"inforule"},[_vm._v("Based on the amount of your Total NFT Assets, there'll be different Amplifiers:")]),_vm._m(5)]),_vm._m(6)])])]),_c('div',{staticClass:"pointsmission"},[_c('div',{staticClass:"center connectBoxW"},[_c('div',[_c('h1',[_vm._v("Points Mission")]),_c('p',{staticClass:"titletip"},[_vm._v("— — The points rules may be adjusted as appropriate — —")]),_vm._m(7),_c('ul',{staticClass:"pointsmissionlist"},[_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('li',[_c('div',[_c('div',{staticClass:"titlebox"},[_vm._m(12),_c('router-link',{attrs:{"tag":"div","to":"/xSPACE"}},[_vm._v("Convert")])],1),_vm._m(13),_c('div',{staticClass:"border"}),_vm._m(14)])])])]),_c('v-Footer')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannerImg"},[_c('img',{staticClass:"bg1",attrs:{"src":require("@/assets/Points/banner.png"),"alt":""}})]),_c('div',{staticClass:"center connectBoxW"},[_c('div',{staticClass:"bigTitle"},[_c('h1',[_vm._v("Points")]),_c('p',[_vm._v("Record you contribution in liquidity and trading on Blast. Share the potential incentive by Blaspace and Blast according to your points.")]),_c('p',[_vm._v("The points can also be exchanged to rewards such as Planet NFT, xSPACE, WL, etc.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"notice",attrs:{"href":"https://twitter.com/blaspace_io/status/1704453278954914246?s=20","target":"_block"}},[_vm._v("Reward Redemption Phase2 (09.20-10.05): 5000points/Planet NFT, 300points/50 xSPACE! More info "),_c('img',{attrs:{"src":require("@/assets/Points/noticeright.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("Points Store")]),_c('p',{staticClass:"redeem"},[_vm._v("Redeem")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('span',[_vm._v("Hold Planet NFT to boost the Points")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',[_vm._v("Total NFT Assets")]),_c('span',[_vm._v("0 SPACE")])]),_c('p',[_c('span',[_vm._v("Current Amplifier")]),_c('span',[_vm._v("0 x")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"inforules"},[_c('li',[_c('p',[_vm._v("Under 5,000, Amplifier "),_c('span',[_vm._v("1.1x")])]),_c('p',[_vm._v("5,000-10,000, Amplifier "),_c('span',[_vm._v("1.15x")])])]),_c('li',[_c('p',[_vm._v("10,000-20,000, Amplifier "),_c('span',[_vm._v("1.2x")])]),_c('p',[_vm._v("20,000-50,000, Amplifier "),_c('span',[_vm._v("1.25x")])])]),_c('li',[_c('p',[_vm._v("50,000-100,000, Amplifier "),_c('span',[_vm._v("1.3x")])]),_c('p',[_vm._v("100,000-150,000, Amplifier "),_c('span',[_vm._v("1.35x")])])]),_c('li',[_c('p',[_vm._v("150,000-200,000, Amplifier "),_c('span',[_vm._v("1.4x")])]),_c('p',[_vm._v("Above 200,000, Amplifier "),_c('span',[_vm._v("1.55x")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inforight"},[_c('img',{attrs:{"src":require("../assets/Points/planetnft.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tip"},[_c('span',[_vm._v("Real-time data on Liquidity, Volume, and Holders will be Updated Weekly.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('div',{staticClass:"titlebox"},[_c('p',[_c('span',[_vm._v("Add and Hold Liquidity")]),_c('span',[_vm._v("On SpaceFi")])]),_c('div',[_vm._v("Add")])]),_c('div',{staticClass:"rule"},[_c('p',[_vm._v("Eligible LP: $0.000000")]),_c('p',[_vm._v("$ 100 liquidity, 1 point / day")])]),_c('div',{staticClass:"border"}),_c('div',{staticClass:"textinfo"},[_vm._v("The points will be accumulated based on your holding liquidity and time: $100 liquidity, 1 point/day. (Formula: points=liquidity/100*days).")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('div',{staticClass:"titlebox"},[_c('p',[_c('span',[_vm._v("Add and Hold $SPACE Liquidity")]),_c('span',[_vm._v("On SpaceFi")])]),_c('div',[_vm._v("Add")])]),_c('div',{staticClass:"rule"},[_c('p',[_vm._v("Eligible LP: $0.000000")]),_c('p',[_vm._v("$ 40 liquidity, 1 point / day")])]),_c('div',{staticClass:"border"}),_c('div',{staticClass:"textinfo"},[_vm._v("The points will be accumulated based on your holding $SPACE liquidity and time: $40 liquidity, 1 point/day. (Formula: points=$SPACE liquidity/40*days).")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('div',{staticClass:"titlebox"},[_c('p',[_c('span',[_vm._v("Trading Volume")]),_c('span',[_vm._v("Of the pairs in Space Farm")])]),_c('div',[_vm._v("Trade")])]),_c('div',{staticClass:"rule"},[_c('p',[_vm._v("Eligible Volume: $0.000000")]),_c('p',[_vm._v("$ 50 volume, 1 point")])]),_c('div',{staticClass:"border"}),_c('div',{staticClass:"textinfo"},[_vm._v("The points will be accumulated based on your trading volume: $50 volume, 1 point. (Formula: points=total volume/50).")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('div',{staticClass:"titlebox"},[_c('p',[_c('span',[_vm._v("Trade $SPACE")]),_c('span',[_vm._v("On SpaceFi")])]),_c('div',[_vm._v("Trade")])]),_c('div',{staticClass:"rule"},[_c('p',[_vm._v("Eligible Volume: $0.000000")]),_c('p',[_vm._v("$ 20 volume, 1 point")])]),_c('div',{staticClass:"border"}),_c('div',{staticClass:"textinfo"},[_vm._v("The points will be accumulated based on your $SPACE trading volume: $20 volume, 1 point. (Formula: points=total volume/20).")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("xSPACE Holder")]),_c('span',[_vm._v("except unbonding")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule"},[_c('p',[_vm._v("Holding Amount: 0.000000 xSPACE")]),_c('p',[_vm._v("100 xSPACE, 1 point / day")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textinfo"},[_vm._v("The points will be accumulated based on your holding xSPACE and time: 100 xSPACE, 1 point/day. (Formula: points=xSPACE/100*days)."),_c('br'),_vm._v("It will include the xSPACE in single pool and your wallet, except the xSPACE in unbonding.")])
}]

export { render, staticRenderFns }